<template>
<div v-if="getAllowFullSite" class="main content-page four-crucial-values">

    <div class="vertical-image"></div>

    <div class="content-page-inner">
        <h1>Four Values Crucial to Catalyze Revival at Home and Abroad</h1>

        <h2 id="#unity">Unity</h2>
        <p>A house divided cannot stand. Despite this reality, as well as multiple calls in scripture for unity among God’s people, Christianity in America can sometimes function in a spirit of independence rather than godly collaboration. But to witness a great move of God in this season, titles and position as well as traditions and divisive doctrinal positions will need to become secondary to the things that unite us, rather than divide us. we must commit to great humility role, to contribute according to our unique ability and capacity.</p>

        <h2 id="#awakening">Awakening</h2>
        <p>Jesus taught that the Church is the salt and light of the earth, the only effective transformative and preservative agent in society (Eph. 3:6). But in order for the Church to maintain our role and identity, we must maintain our “sincere and pure devotion to Christ” (II Cor. 11:3). Today, too many in the Church have been influenced by the decadent society around us, often unthinkingly adopting secular cultural values rather than holding fast to relationship with Christ and biblical truth and values. As morality in America declines and acceptance of sin increases, the Church’s transformative and preservative power is weakened, if not lost. Christians must be awakened by re-committing to a lifestyle of daily personal relationship time with the Lord, repentance, prayer, fasting, bible reading, and practicing biblical acts of service for Christ and His Kingdom.</p>

        <h2 id="#revival">Revival</h2>
        <p>Once believers return to her their first love (Rev. 2:4), then they have both the power and authority to be an agent of change in society. As believers are equipped, empowered, and motivated to all Seven Spheres of Influence in society, converts and disciples are multiplied and the Gospel of the Kingdom goes viral. This is revival! Historically, entire nations and cultures shift towards a biblical worldview when around one-third of the population have become dedicated followers of Jesus Christ. Just as Awakening makes Revival possible, Revival then makes Cultural Reformation possible. This transformation cannot be achieved through either activism or legislation, but only through awakened followers of Jesus intentionally influencing the Sphere of society they work in towards Christ and a biblical worldview. As more and more people are personally transformed, they have an increasing impact and influence on institutions, the culture, and their entire society. ”But thanks be to God, who in Christ always leads us in triumphal procession, and through us spreads the fragrance of the knowledge of Him everywhere.” (II Cor. 2:14)</p>

        <h2 id="#the-gospel">The Gospel</h2>
        <p>The Gospel is the blessing of God and the Church is the agent of the blessing. We are blessed to be a blessing. When Christ ascended into heaven, He left the Church with a task which must be completed before He can return, saying that the Gospel must first be preached to all nations before the end would come. Each superpower era has brought us closer to that goal, and each has seen greater harvests than the previous. The British Empire launched two missions movements with the second one seeing over 100,000 young missionaries sent out in the Student Volunteer Movement of the late 1800’s. When America attained sole superpower status in the early 1990’s, the AD2000 missions movement launched which saw more people come into the Kingdom than in all of Church history combined! But the task isn’t finished, for the Great Commission is not yet completed. We have a second missions movement left in us, one which could dwarf what the Church experienced in the last movement. We have the capacity to launch 200,000 new workers to the Unreached harvest fields of the earth in order to fulfill God’s deepest desire and scripture’s ultimate purpose. God is calling, the world is waiting and time is ticking. The choice is ours.</p>

        <router-link to="/contact"><button class="button-dark last-button">Ready to Connect?</button></router-link>
    </div>
</div>
</template>

<script>
export default {
    name: 'four-crucial-values',
    data() {
        return {
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        }
    },
    mounted() {
        if (this.$route.hash) {
            var element = document.getElementById(this.$route.hash);
            var top = element.offsetTop;
            window.scrollTo(0, top - 12);
        } else {
            window.scrollTo(0, 0);
        }
    }
}
</script>

<style lang="scss" scoped>

.vertical-image {
    overflow: hidden;
    background-image: url("~@/assets/values1.jpg");
    background-size: cover;
    background-position: center bottom;
}
.content-page-inner {
    padding: 4em;
    text-align: left;
}
</style>
